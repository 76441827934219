import { AuthService } from './../../../services/storage/auth.service';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Input,
  inject,
} from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { MatAccordion, MatExpansionPanelHeader } from '@angular/material/expansion';
import { PreferencesService } from 'src/app/services/backend/preferences.service';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { BehaviorSubject, filter, lastValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { TourService } from '@services/components/tour.service';
import { CommingSoonModule } from '@components/comming-soon/comming-soon.module';
import { ApiService } from '@services/backend/api.service';
import { MembershipService } from '@services/backend/membership.service';
import { MembershipsLiveService } from '@services_shared/live/memberships-live.service';

@Component({
  selector: 'v-subMenuMain',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Output() forceCloseMenuMobileEmit: EventEmitter<any> = new EventEmitter();
  @Input('reset') reset: boolean = false;
  @ViewChild(MatAccordion)
  accordion!: MatAccordion;
  public principalAccordionActive: number | null = null;
  public accordionActive: number | null = null;
  public hrefToAcordion: string = document.URL.toLowerCase();
  public isAttentionView: boolean = false;

  public isConversaciones = this.hrefToAcordion.includes('/dashboard/attentions/conversations');
  public isContactCenter: boolean = false;
  public isWhatsapp = this.hrefToAcordion.includes('/dashboard/channels/whatsapp');
  public isFacebook = this.hrefToAcordion.includes('/dashboard/channels/facebook');
  public isInstagram = this.hrefToAcordion.includes('/dashboard/channels/instagram');

  public isConfigurationView = this.hrefToAcordion.includes('/dashboard/configurations');
  public isAnalytics = this.hrefToAcordion.includes('/dashboard/analytics');
  public isChannel = this.hrefToAcordion.includes('/dashboard/channel');
  public isProfile = this.hrefToAcordion.includes('/dashboard/profile');
  // public isPrincipalCC = this.hrefToAcordion.includes('/dashboard/channels')
  public screenWidth: any;
  public getScreenHeight: any;
  currentRoute: any;
  subMenuArray: any = [];
  private userInfo: any;
  public company: any = { name: '', imgSrc: '' };
  public _unSuscribeAll: Subject<any> = new Subject();

  private membershipsLiveSrc: MembershipsLiveService = inject(MembershipsLiveService);

  analyticsItems: Array<{ label: string; link: Array<string>; icon: string; permissions: Array<string>; onclick?: any }> = [
    {
      link: ['/dashboard/analytics/attentions'],
      icon: 'atenciones2',
      label: 'Atenciones',
      permissions: ['co.anlAttention.all', 'co.anlAttention.view'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      link: ['/dashboard/analytics/satisfaction'],
      icon: 'satisfaccion',
      label: 'Satisfacción',
      permissions: ['*'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },

    {
      link: ['/dashboard/analytics/resolutivity'],
      icon: 'resolutividad',
      label: 'Resolutividad',
      permissions: ['*'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
  ];

  attentionsItems = [
    {
      link: ['/dashboard/attentions/list'],
      icon: 'atenciones',
      label: 'Atenciones',
      permissions: ['co.conversation.all', 'co.conversation.list'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      link: ['/dashboard/attentions/real-time'],
      icon: 'real-time',
      label: 'Tiempo real',
      permissions: ['co.conversation.all', 'co.conversation.listCurrent'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      link: ['/dashboard/attentions/queued'],
      icon: 'queued',
      label: 'En cola',
      permissions: ['co.conversation.all', 'co.conversation.listQueued'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
  ];

  ajustesItems: Array<any> = [
    {
      id: 'triggerPathUsers',
      link: ['/dashboard/configurations/users'],
      icon: 'usuarios',
      label: 'Usuarios',
      permissions: ['co.user.all', 'co.user.list'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      id: 'triggerPathContacts',
      link: ['/dashboard/configurations/contacts'],
      icon: 'contactos',
      label: 'Contactos',
      permissions: ['co.contact.all', 'co.contact.list'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      id: 'triggerPathGroups',
      link: ['/dashboard/configurations/groups'],
      icon: 'grupos',
      label: 'Grupos',
      permissions: ['co.group.all', 'co.group.list'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    {
      id: 'triggerPathTypesAttention',
      link: ['/dashboard/configurations/type-of-attentions'],
      icon: 'tipo_de_atenciones',
      label: 'Tipos de atención',
      permissions: ['co.attentionType.all', 'co.attentionType.list'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    // Menus
    {
      id: 'triggerPathMenus',
      link: ['/dashboard/configurations/menus'],
      icon: 'menus',
      label: 'Menús',
      permissions: [],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },

    // {
    // 	id: 'triggerPathMsgCustom',
    // 	link: ['/dashboard/configurations/msg-custom'],
    // 	icon: 'icon_msg_custom',
    // 	label: 'Mensajes',
    // 	permissions: ['co.msg.all', 'co.msg.list'],
    // 	onclick: this.closeAllAccordionAndForceMobile.bind(this),
    // },
    // {
    // 	id: 'triggerPathRolesAndPermission',
    // 	link: ['/dashboard/configurations/roles-and-permission'],
    // 	icon: 'roles-and-permission',
    // 	label: 'Roles y permisos',
    // 	permissions: ['co.role.all', 'co.role.list'],
    // 	onclick: this.closeAllAccordionAndForceMobile.bind(this),
    // },
    // {
    // 	id: 'triggerPathHistory',
    // 	link: ['/dashboard/configurations/history'],
    // 	icon: 'history',
    // 	label: 'Historial',
    // 	permissions: ['*'],
    // 	onclick: this.closeAllAccordionAndForceMobile.bind(this),
    // },
  ];
  profileItems: Array<any> = [
    {
      link: ['/dashboard/profile/account'],
      icon: 'v-account',
      label: 'Cuenta',
      permissions: ['*'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
    // {
    // 	link: ['/dashboard/profile/invoices'],
    // 	icon: 'v-invoices',
    // 	label: 'Facturación',
    // 	permissions: ['*'],
    // 	onclick: this.closeAllAccordionAndForceMobile.bind(this),
    // },
    // {
    // 	link: ['/dashboard/profile/payments'],
    // 	icon: 'v-payments',
    // 	label: 'Pagos',
    // 	permissions: ['co.payments.all', 'co.payments.list'],
    // 	onclick: this.closeAllAccordionAndForceMobile.bind(this),
    // },
    {
      link: ['/dashboard/profile/pricing'],
      icon: 'v-pricing',
      label: 'Planes y costos',
      permissions: ['co.pricing.all', 'co.pricing.view'],
      onclick: this.closeAllAccordionAndForceMobile.bind(this),
    },
  ];
  whatsappMembership: boolean = false;
  facebookMembership: boolean = false;
  instagramMembership: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private _preferencesService: PreferencesService,
    private _membershipService: MembershipService,
    private _dimensionsScreenSrc: DimensionsScreenService,
    private _tourSrc: TourService,
    private api: ApiService
  ) {
    this._dimensionsScreenSrc.change
      .pipe(
        tap(({ width }) => {
          this.screenWidth = width;
        })
      )
      .subscribe();

    this._tourSrc.actions
      .pipe(
        tap(({ action, config }) => {
          if (action === 'openAccordeonSubMenu') {
            this.accordionActive = config.indexAccordeonActive;
            this.principalAccordionActive = config.indexAccordeonActive;
          }
          if (action === 'openPrincipalAccordeonSubMenu') {
            this.principalAccordionActive = config.indexAccordeonActive;
          }
        })
      )
      .subscribe();

    this.router.events
      .pipe(filter((item) => item instanceof NavigationEnd || item instanceof NavigationError || item instanceof NavigationCancel))
      .subscribe(() => {
        this.setAccordeonActive();
      });

    this.setAccordeonActive();
    this.initData();
    this._preferencesService.onChange
      .pipe(
        takeUntil(this._unSuscribeAll),
        tap(() => this.getPreferencesInfo())
      )
      .subscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reset'] && this.reset === true) {
      // this.accordion.closeAll();
      this.reset = false;
      this.accordionActive = null;
      this.principalAccordionActive = null;
    }
  }

  initData() {
    this._membershipService.getMembership().subscribe((dataMembership: any) => { });
  }
  getPreferencesInfo() {
    this._preferencesService
      .getPreferences(this.userInfo.company)
      .pipe(
        takeUntil(this._unSuscribeAll),
        tap(({ banner }) => {
          this.company.imgSrc = banner;
        })
      )
      .subscribe();
  }
  ngOnInit(): void {
    this.userInfo = this.authService.getInfo();

    this._preferencesService
      .getPreferences(this.userInfo.company)
      .pipe(
        takeUntil(this._unSuscribeAll),
        tap(({ banner }) => {
          this.company.imgSrc = banner;
        })
      )
      .subscribe();
    // this._membershipService.membershipOfCompany
    // 	.pipe(
    // 		takeUntil(this._unSuscribeAll),
    // 		tap((membershipData) => {
    // 			if (Object.keys(membershipData).length === 0) return membershipData;
    // 			this.whatsappMembership = membershipData.membership.current.WA || false;
    // 			this.facebookMembership = membershipData.membership.current.FB || false;
    // 			this.instagramMembership = membershipData.membership.current.IG || false;
    // 		})
    // 	)
    // 	.subscribe();

    const { WA, FB, IG } = this.membershipsLiveSrc.membership.getValue();

    this.whatsappMembership = WA || false;
    this.facebookMembership = FB || false;
    this.instagramMembership = IG || false;

    this.membershipsLiveSrc.membership.pipe(takeUntil(this._unSuscribeAll)).subscribe(() => {
      const { WA, FB, IG } = this.membershipsLiveSrc.membership.getValue();

      this.whatsappMembership = WA || false;
      this.facebookMembership = FB || false;
      this.instagramMembership = IG || false;
    });

    this.company.name = this.userInfo.company_name;
  }

  setAccordionActive(index: number) {
    index === this.accordionActive ? (this.accordionActive = null) : (this.accordionActive = index);
  }
  setPrincipalAccordionActive(index: number) {
    index === this.principalAccordionActive ? (this.principalAccordionActive = null) : (this.principalAccordionActive = index);
  }
  ngOnDestroy() {
    this._unSuscribeAll.next({});
    this._unSuscribeAll.complete();
  }

  forceCloseMenuMobile($event: MouseEvent): void {
    this.forceCloseMenuMobileEmit.emit($event);
  }

  isRoute(string: string) {
    return this.hrefToAcordion.includes(string);
  }

  setAccordeonActive() {
    let url = this.router.url;

    this.isAttentionView =
      url.includes('/dashboard/attentions') &&
      !url.includes('/users') &&
      !url.includes('/contacts') &&
      !url.includes('/groups') &&
      !url.includes('/type-of-attentions') &&
      !url.includes('/aplications');

    this.isContactCenter = url.includes('/dashboard/channels/contact-center');
    this.isFacebook = url.includes('/dashboard/channels/facebook');
    this.isInstagram = url.includes('/dashboard/channels/instagram');

    this.isWhatsapp = url.includes('/dashboard/channels/whatsapp');
    this.isConfigurationView = url.includes('/dashboard/configurations');
    this.isAnalytics = url.includes('/dashboard/analytics');
    this.isChannel = url.includes('/dashboard/channels');
    this.isProfile = url.includes('/dashboard/profile');

    this.currentRoute = url.split('/')[2];
  }

  closeAllAccordionAndForceMobile(ev: any) {
    this.accordion?.closeAll();
    this.setAccordeonActive();
    this.forceCloseMenuMobile(ev);
  }
}
